import { navigate } from 'gatsby';
import { useAuth0 } from '@auth0/auth0-react';

export const redirectCallbackAfterLogin = () => {
  const currentRoute = localStorage.getItem('currentRoute') || '/';
  return navigate(currentRoute);
};

export const useCheckNotAuthenticatedUsers = () => {
  const { isAuthenticated, user } = useAuth0();
  const isBrowser = typeof window !== `undefined`;
  if (
    !isAuthenticated &&
    isBrowser &&
    window.location.pathname !== `/auth/login`
  ) {
    localStorage.setItem(
      'currentRoute',
      `${window.location.pathname}${window.location.search}`
    );
    return true;
  } else if (
    isAuthenticated &&
    !user.email_verified &&
    isBrowser &&
    window.location.pathname !== `/auth/login`
  ) {
    return 'unverified';
  }
};
