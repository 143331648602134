import React from 'react';
import { navigate } from 'gatsby';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { ApolloProvider } from './src/services/graphql/ApolloProvider';
import { config } from './src/services/authentication/auth_config';
import 'reset-css';
import { redirectCallbackAfterLogin } from './src/shared/helpers/Auth';
import Spinner from './src/components/Spinner';
import ErrorBoundary from './src/features/ErrorBoundaries';
import './src/styles/index.scss';

export const wrapRootElement = ({ element }) => {
  const CheckSession = ({ children }) => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    if (isAuthenticated && !user.email_verified) {
      navigate('/auth/unverified');
    }

    return (
      <ErrorBoundary>
        {isLoading && <Spinner />}
        {children}
      </ErrorBoundary>
    );
  };

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.client_id}
      redirectUri={window.location.origin}
      scope={config.scope}
      audience={config.audience}
      onRedirectCallback={redirectCallbackAfterLogin}
    >
      <CheckSession>
        <ApolloProvider>{element}</ApolloProvider>
      </CheckSession>
    </Auth0Provider>
  );
};
