import React from 'react';
import { sendErrorsToSentry } from 'shared/helpers/Errors';
class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // call error logging or reporting service
    sendErrorsToSentry(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const isBrowser = typeof window !== undefined;
      if (isBrowser) {
        window.location.replace('/error');
      }
      return null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
